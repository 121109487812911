<template>
  <div>
    <div class="text-center fixed w-full z-10">
      <div class="lg:text-center py-2 md:py-6 px-10 bg-white border-b border-gray-200">
        <h2
          class="text-base text-indigo-600 font-semibold tracking-wide uppercase"
        >
          Guthrie Soccer
        </h2>
        <p
          class="md:mt-1 text-xl md:text-3xl font-extrabold tracking-tight text-gray-900"
        >
          2024 Fall Season Registration
        </p>
      </div>
    </div>

    <div class="container mx-auto text-center pt-32 pb-12">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {};
  },
};
</script>

<style lang="stylus" scoped></style>